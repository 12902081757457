import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Header from '../components/Header'

import ArrowRight from '../svgs/arrow-right.svg'

export default function Template({ data }) {
  const project = data.project.frontmatter

  return (
    <Layout>
      <Seo />
      <Header
        pagination={{
          prev: data.prev.frontmatter,
          next: data.next.frontmatter,
        }}
      />
      <main role='main'>
        <section className='flex flex-col justify-center min-h-screen'>
          <div className='container py-60'>
            <h1 className='mb-15 md:text-20 lg:text-24'>{project.title}</h1>
            <h2
              className='mb-20 text-24 md:text-32 lg:text-40'
              dangerouslySetInnerHTML={{ __html: project.text }}
            />
            {project.underConstruction ? (
              <span
                role='img'
                aria-label='Under construction'
                className='text-24 md:text-32 lg:text-40'
              >
                🚧
              </span>
            ) : (
              <a
                href={project.link}
                className='border-2 flex flex-col justify-center h-50 items-center rounded-full w-50'
              >
                <span className='w-25'>
                  <ArrowRight />
                </span>
              </a>
            )}
          </div>
        </section>
        <section className='bg-grey max-h-screen relative'>
          <div className='aspect-h-9 aspect-w-16' />
          <div className='absolute filter-screen inset-0'>
            <Img
              fluid={project.image.src.childImageSharp.fluid}
              alt={project.image.alt}
              className='h-full max-w-full object-cover w-full'
            />
          </div>
        </section>
        <section className='flex min-h-screen'>
          <div className='flex flex-col justify-center lowercase py-60 w-full'>
            <div className='container'>
              <div className='gap-x-20 gap-y-40 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 md:justify-items-center'>
                <div>
                  <h3 className='mb-15'>Role</h3>
                  <p className='text-20 md:text-22 lg:text-24'>
                    {project.role}
                  </p>
                </div>
                <div>
                  <h3 className='mb-15'>Year</h3>
                  <p className='text-20 md:text-22 lg:text-24'>
                    {project.year}
                  </p>
                </div>
                <div>
                  <h3 className='mb-15'>Built with</h3>
                  <ul className='text-20 md:text-22 lg:text-24'>
                    {project.builtWith.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  {project.underConstruction ? (
                    <span
                      role='img'
                      aria-label='Under construction'
                      className='text-32 md:text-36 lg:text-40'
                    >
                      🚧
                    </span>
                  ) : (
                    <a
                      href={project.link}
                      className='border-2 flex flex-col justify-center h-50 items-center rounded-full w-50'
                    >
                      <span className='w-25'>
                        <ArrowRight />
                      </span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pb-120'>
          <div className='container'>
            {project.images.map(({ src, alt }, index) => (
              <div
                key={index}
                className='bg-grey relative overflow-hidden p-20 mb-60 last:mb-0 md:p-40 lg:mb-120 lg:p-80'
              >
                <div className='filter-screen relative shadow-xl'>
                  <Img
                    fluid={src.childImageSharp.fluid}
                    alt={alt}
                    className='h-auto max-w-full w-full'
                  />
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const QUERY = graphql`
  query($slug: String!, $prev: Int!, $next: Int!) {
    project: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        id
        title
        underConstruction
        link
        heading
        text
        image {
          src {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        role
        builtWith
        year
        images {
          src {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    prev: markdownRemark(frontmatter: { id: { eq: $prev } }) {
      frontmatter {
        title
        slug
      }
    }
    next: markdownRemark(frontmatter: { id: { eq: $next } }) {
      frontmatter {
        title
        slug
      }
    }
  }
`
